import {Form, Input, InputNumber, Select} from 'antd';
import React from "react";
import FormFieldType from "../../../form/FormElement/formField/FormFieldType";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import {PickerMode} from 'rc-picker/lib/interface';
import MomentJsDateFormat from "../../../../../shared/input/MomentJsDateFormat";
import FormFieldDate from "../../../form/FormElement/formField/FormFieldDate";
import IDateTime from "../../../../../../model/interface/api/IDateTime";
import DatePicker from "../../../../../shared/input/DatePicker";
import {humanTime} from "../../../../file-manager/utils/TimeDuration";

export interface IFieldDateOptions {
    datePicker?: PickerMode | 'dateTime'
    dateFormat?: string
}

interface IProps extends IFieldOptionsEditorProps {

}

class FieldDateOptionsEditor extends React.Component<IProps> {

    render() {
        const {buildFieldName} = this.props
        return (
            <div>
                <Form.Item name={buildFieldName('datePicker')} label={'Typ vstupu'} rules={[{required: true}]}
                           initialValue={FormFieldType.FIELD_DATE_DATE_PICKER}>
                    <Select>
                        {FormFieldType.FIELD_DATE_PICKER_TYPES.map(type => (
                            <Select.Option key={type.value} value={type.value}>{type.label}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name={buildFieldName('dateFormat')} label={'Formát zobrazení'} initialValue={'LL'}>
                    <MomentJsDateFormat/>
                </Form.Item>
            </div>
        )
    }
}


export default FieldDateOptionsEditor