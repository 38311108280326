import React, {RefObject} from "react"
import {Card, Form, FormInstance, Select, Slider, Switch} from "antd";
import {getNestedFieldOptions} from "../unit/ViewUnitProperties";
import Title from "antd/es/typography/Title";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import {DOWNLOAD_REQUEST_FORMAT_LABELS} from "../../../../../model/service/export/DownloadRequestsService";

interface IState {

}

interface IProps {
    contentType: IContentType
    formRef: RefObject<FormInstance>
    values: { [name: string]: string | boolean }
}

class ViewPivotTableProperties extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    render() {
        const {values} = this.props

        return (
            <>
                <Title level={3}>Vlastnosti tabulky</Title>
                <Form.Item
                    valuePropName={'checked'}
                    label={"Povolit export"}
                    name={getNestedFieldOptions('allowExport')}
                >
                    <Switch/>
                </Form.Item>
                {values.allowExport && (
                    <Card size={"small"}>
                        <Form.Item
                            label={"Format"}
                            name={getNestedFieldOptions('exportFormats')}
                        >
                            <Select mode={"multiple"}>
                                {DOWNLOAD_REQUEST_FORMAT_LABELS.map(format => (
                                    <Select.Option value={format.value} key={format.value}>
                                        <span>{format.icon}</span> {format.configLabel || format.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={"Interval requestu"}
                            name={getNestedFieldOptions('exportInterval')}
                        >
                            <Slider tipFormatter={value => `${value}`} marks={{1: '1s', 30: '30s'}} max={30}
                                    min={1} tooltipPlacement={"right"}/>
                        </Form.Item>
                        <Form.Item
                            valuePropName={'checked'}
                            label={"Vytvořit záhlaví"}
                            name={getNestedFieldOptions('exportIncludeHeader')}
                        >
                            <Switch/>
                        </Form.Item>
                    </Card>
                )}
                <Form.Item
                    valuePropName={'checked'}
                    label={"Zobrazit měnič velikosti stránky"}
                    name={getNestedFieldOptions('allowPageSizeChanger')}
                >
                    <Switch/>
                </Form.Item>
            </>
        )
    }
}

export default ViewPivotTableProperties