import React from "react";
import {Button, Input, InputNumber} from "antd";


export type Section = 'second' | 'minute' | 'hour' | 'day' | 'week'

interface IProps {
    value?: number,
    onChange?: (value: number) => void
    maxSection?: Section
}

class TimeSectionsPicker extends React.Component<IProps> {

    render() {
        const {value, onChange, maxSection} = this.props;

        const totalSeconds = (value || 0)
        let remainder = totalSeconds
        let weeks = 0, days = 0, hours = 0, minutes = 0, seconds = 0
        if (!maxSection || maxSection === 'week'){
            weeks = Math.floor(totalSeconds / 604800);
            remainder = totalSeconds % 604800;
        }

        if (!maxSection || ['week', 'day'].includes(maxSection)){
            days = Math.floor(remainder / 86400);
            remainder = remainder % 86400;
        }

        if (!maxSection || ['week', 'day', 'hour'].includes(maxSection)){
            hours = Math.floor(remainder / 3600);
            remainder = remainder % 3600;
        }

        if (!maxSection || ['week', 'day', 'hour', 'minute'].includes(maxSection)){
            minutes = Math.floor(remainder / 60);
            remainder = remainder % 3600;
        }

        seconds = maxSection === 'second' ? remainder : remainder % 60;

        return <Input.Group compact={true}>
            {(!maxSection || maxSection === 'week') && <>
                <InputNumber style={{width: 60}} defaultValue={weeks} onChange={i => onChange?.(i * 604800 + (totalSeconds - weeks * 604800))}/>
                {this.unitLabel('w')}
            </>}
            {(!maxSection || ['week', 'day'].includes(maxSection)) && <>
                <InputNumber style={{width: 60}} defaultValue={days} onChange={i => onChange?.(i * 86400 + (totalSeconds - days * 86400))}/>
                {this.unitLabel('d')}
            </>}
            {(!maxSection || ['week', 'day', 'hour'].includes(maxSection)) && <>
                <InputNumber style={{width: 60}} defaultValue={hours} onChange={i => onChange?.(i * 3600 + (totalSeconds - hours * 3600))}/>
                {this.unitLabel('h')}
            </>}
            {(!maxSection || ['week', 'day', 'hour', 'minute'].includes(maxSection)) && <>
                <InputNumber style={{width: 60}} defaultValue={minutes} onChange={i => onChange?.(i * 60 + (totalSeconds - minutes * 60))}/>
                {this.unitLabel('m')}
            </>}
            <>
                <InputNumber style={{width: 60}} defaultValue={seconds} onChange={i => onChange?.(i + (totalSeconds - seconds))}/>
                {this.unitLabel('s')}
            </>
        </Input.Group>
    }

    private unitLabel(label: string) {
        return <Button disabled={true}>{label}</Button>;
    }
}

export default TimeSectionsPicker