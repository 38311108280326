import React from "react";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import FormFieldType from "./FormFieldType";
import LocaleProvider from "../../../../../../i18n/LocaleProvider";
import DatePicker from "components/shared/input/DatePicker";
import TimePicker from "components/shared/input/TimePicker";
import moment, {Moment} from "moment";
import {range} from "underscore";

class FormFieldDate {
    public static render = (options: IFieldOptions) => {
        const props = {
            style: options.style,
            placeholder: options.placeholder,
            allowClear: options.showClear,
            disabled: options.disabled,
            value: options.value,
            format: '' || options.datePickerFormat,
            confirm: options.datePickerConfirm
        };
        const isDisabledDate = (date: Moment) =>
            (!!options.datePickerAllowedFrom && date.unix() <= moment().subtract(options.datePickerAllowedFrom, 's').unix()) || (!!options.datePickerAllowedTo && date.unix() >= moment().add(options.datePickerAllowedTo, 's').unix())
        const isDisabledTime = (date: Moment | null) =>
        {
            return {
                disabledHours: () => range(0, 24).filter(hour => !date || !isDisabledDate(date) || (date.hour() > moment().subtract(options.datePickerAllowedFrom, 's').hour() && date.hour() < moment().add(options.datePickerAllowedTo, 's').hour() ) ),
                disabledMinutes: () => range(0, 60).filter(hour => !date || !isDisabledDate(date) || (date.minute() > moment().subtract(options.datePickerAllowedFrom, 's').minute() && date.minute() < moment().add(options.datePickerAllowedTo, 's').minute() ) ),
                disabledSeconds: () => range(0, 60).filter(hour => !date || !isDisabledDate(date) || (date.second() > moment().subtract(options.datePickerAllowedFrom, 's').second() && date.second() < moment().add(options.datePickerAllowedTo, 's').second() ) ),
            }
        }
        switch (options.datePicker) {
            case FormFieldType.FIELD_DATE_DATE_PICKER:
                return <DatePicker disabledDate={isDisabledDate} locale={LocaleProvider.getPicker()} {...props} />
            case FormFieldType.FIELD_DATE_DATE_TIME_PICKER:
                return <DatePicker disabledDate={isDisabledDate} disabledTime={isDisabledTime} showTime={{format: options.datePickerTimeFormat}} locale={LocaleProvider.getPicker()} {...props} />
            case FormFieldType.FIELD_DATE_TIME_PICKER:
                return <TimePicker locale={LocaleProvider.getPicker()} {...props} format={options.datePickerTimeFormat} />
            default:
                return <DatePicker disabledDate={isDisabledDate} mode={options.datePicker} locale={LocaleProvider.getPicker()} {...props} />
        }
    }
}

export default FormFieldDate